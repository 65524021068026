import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>CONTACTS</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">CONTACTS</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <a href="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="def-section">
        <div className="container">
          <div className="row">
            {/* === CONTACTS INFO === */}
            <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
              <div className="contacts-info">
                <div className="contacts-info-title">
                  <h3>
                    We will be Happy
                    <br />
                    to help you
                  </h3>
                </div>
                <div className="">
                  <h3>
                    Don't hesitate to contact us – our team is ready to provide
                    tailored solutions for your freight forwarding requirements.
                  </h3>
                </div>
              </div>
            </div>
            {/* === CONTACTS FORM === */}
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
              <div className="contacts-form row">
                <div className="send-result" />
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="contacts-form-item">
                        <input
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                        />
                        <span />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contacts-form-item">
                        <input
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="contacts-form-item">
                        <input
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                            background: "none",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="contacts-form-item">
                        <input
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="contacts-form-item">
                        <textarea
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12 contacts-form-item">
                      <button type="submit">
                        <span className="my-btn my-btn-grey">
                          <span className="my-btn-bg-top" />
                          <span className="my-btn-bg-bottom" />
                          <span className="my-btn-text">Send Message</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
                <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========================
		CONTACTS DETAILS
	============================== */}
      <div className="def-section contact-details">
        <div className="container">
          <div className="row">
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail">
              <div className="contact-detail-icon">
                <i className="flaticon-map58" />
              </div>
              <div className="contact-detail-title">
                <h3>LOCATION</h3>
              </div>
              <div className="contact-detail-text">{companyaddress}</div>
            </div>
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail contact-detail-mark">
              <div className="contact-detail-icon">
                <i className="flaticon-telephone5" />
              </div>
              <div className="contact-detail-title">
                <h3>PHONE </h3>
              </div>
              <div className="contact-detail-text">
                {companynumber}
                <br />
                <br />
              </div>
            </div>
            {/* === CONTACTS DETAILS ITEM === */}
            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 contact-detail ">
              <div className="contact-detail-icon">
                <i class="fi fi-rr-envelope"></i>
              </div>
              <div className="contact-detail-title">
                <h3> EMAIL</h3>
              </div>
              <div className="contact-detail-text">{companyemail}</div>
            </div>
          </div>
        </div>
      </div>
      {/* =========================
		END CONTACTS DETAILS
	============================== */}
      {/* =========================
		CONTACTS MAP
	============================== */}
      <div className="contact-map" id="contact-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15088.11913575297!2d73.01887897906063!3d19.018409193934218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3e7738c6f25%3A0x2142f221fb431f03!2sPlot%20no%2051!5e0!3m2!1sen!2sin!4v1690454638533!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>

      <Footer />
    </>
  );
}
