import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>WAREHOUSING</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">WAREHOUSING</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li>
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li className="active">
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>WAREHOUSING</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/warehousing.webp" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    Leverage the exceptional capabilities of our
                    state-of-the-art warehouses, meticulously designed to meet
                    the demands of modern logistics. Equipped with the latest
                    technology and operated by a team of experienced
                    professionals, our facilities stand ready to cater to your
                    cargo needs across various regions. From metropolitan hubs
                    to remote areas, our scalable supply chain solutions ensure
                    your goods are handled with utmost care and efficiency.
                    <br />
                    <br />
                    Our warehouses are at the forefront of innovation, boasting
                    cutting-edge equipment and smart technology to optimize
                    operations. Our team of trained professionals is committed
                    to providing top-notch service, ensuring that your cargo is
                    handled with precision and delivered promptly. We take pride
                    in our dedication to quality, safety, and reliability,
                    setting new industry standards for warehouse management.
                    <br />
                    <br />
                    Experience the peace of mind that comes with entrusting your
                    goods to our advanced warehouses. With a customer-centric
                    approach, we tailor our logistics solutions to meet your
                    unique requirements, helping you navigate the complexities
                    of supply chain management with ease. Whether you need
                    short-term storage or long-term warehousing solutions, our
                    facilities are designed to accommodate a wide range of
                    needs, allowing your business to thrive in a rapidly
                    changing market.
                    <br />
                    <br />
                    Partner with us and access a network of strategically
                    located warehouses, providing comprehensive coverage and
                    accessibility to key markets. From inventory management to
                    order fulfillment, our warehouses form an integral part of
                    our end-to-end logistics services, offering you a seamless
                    and efficient supply chain experience. Join the ranks of
                    satisfied clients who have harnessed the power of our
                    cutting-edge facilities to elevate their businesses and
                    achieve market leadership in their respective industries.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
