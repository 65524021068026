import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>OCEAN FREIGHT</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">OCEAN FREIGHT</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li className="active">
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li>
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>OCEAN FREIGHT</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/ocen.jpg" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    At {companyname}, we are dedicated to ensuring that your
                    global ocean freight moves smoothly and efficiently around
                    the world. With our extensive network and major ocean
                    shipping alliances, you can access more capacity and
                    competitive rates, optimizing your ocean shipping
                    operations.
                    <br />
                    <br />
                    No matter the market conditions, we secure capacity even in
                    tight markets, making us a leading forwarder of ocean
                    freight from China to the U.S. in the Transpacific Eastbound
                    (TPEB) trade lane. Our team of ocean freight experts is
                    always ready to troubleshoot and adapt to fluctuating market
                    demand, providing real-time solutions across different time
                    zones.
                    <br />
                    <br />
                    By choosing {companyname}, you can reduce the need for
                    multiple providers, as we offer a comprehensive global suite
                    of services. From ocean freight and air cargo to customs
                    clearance and surface transportation, we have all your
                    logistics needs covered under one roof.
                    <br />
                    <br />
                    With our commitment to excellence and customer satisfaction,
                    you can trust us to handle your ocean freight needs with
                    utmost professionalism and efficiency. Let us take care of
                    your cargo, so you can focus on growing your business and
                    reaching new markets with ease.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
