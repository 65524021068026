import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
        <footer className="def-section footer">
          <div className="container">
            <div className="row">
              {/* === FOOTER COLUMN === */}
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-1">
                <div className="logo with-border-bottom">
                  <div className="logo-image">
                    <img src="/img/logo.png" alt="" style={{height:"80px"}} />
                  </div>
                  
                </div>
                <div className="footer-1-text">
                  <p>Praesent vestibulum dapibus nibh. Etiam iaculis nunc ac metus. Ut id nisl quis enim dignissim sagittis. 
                    Etiam sollicitudin, ipsum pulvinar rutrum,tellus ipsum. laoreet </p>
                </div>
                <div className="footer-1-button">
                  <Link to="/About"><div className="my-btn my-btn-primary">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">
                        MORE
                      </div>
                    </div></Link>
                </div>
              </div>
              {/* === FOOTER COLUMN === */}
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-2">
                <h4 className="with-square with-border-bottom">IMPORTANT LINKS</h4>
                <div className="footer-2-links">
                  <div className="footer-2-links-1">
                    <ul>
                      <li><Link to="/Home">Home</Link></li>
                      <li><Link to="/About">About</Link></li>
                      <li><Link to="/Services">Services</Link></li>
                      <li><Link to="/Contact">Contact</Link></li>
                      <li><Link to="/Getquote">Getquote</Link></li>
                      <li><Link to="/Privacy">Privacy Policy</Link></li>
                      <li><Link to="/Terms">Terms</Link></li>
                     
                    </ul>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-2">
                <h4 className="with-square with-border-bottom">FREIGHT SOLUTIONS</h4>
                <div className="footer-2-links">
                  <div className="footer-2-links-1">
                    <ul>
                    <li>
                        <Link to="/Ocean">Ocean Freight</Link>
                      </li>
                      <li>
                        <Link to="/Air">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Road">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Ocean">OCEAN FREIGHT</Link>
                      </li>
                      <li>
                        <Link to="/Multimodal">Multimodal Transport</Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">Warehousing</Link>
                      </li>
                     
                    </ul>
                  </div>
                  
                </div>
              </div>
              {/* === FOOTER COLUMN === */}
              <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-3">
                <h4 className="with-square with-border-bottom">CONTACT US ON</h4>
                <div className="footer-3-phone footer-3-item">
                  <span className="footer-3-icon"><i className="fa fa-phone" /></span>
                  Phone: {companynumber}
                </div>
               
                <div className="footer-3-mail footer-3-item">
                  <span className="footer-3-icon"><i className="fa fa-envelope" /></span>
                  E-mail: {companyemail}
                </div>
                <div className="footer-3-adress footer-3-item">
                  <span className="footer-3-icon"><i className="fa fa-map-marker" /></span>
                  Adress: {companyaddress} 
                </div>
              </div>
              {/* === FOOTER COLUMN === */}
              
            </div>
          </div>
        </footer>
        {/* ===================================
		END FOOTER
	======================================== */}
        {/* ===================================
		BOTTOM SECTION
	======================================== */}
        <div className="bottom">
          <div className="container">
            <div className="row">
              {/* === BOTTOM LEFT === */}
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-1">
                COPYRIGHT 2023 | {companyname}
              </div>
              {/* === BOTTOM CENTER === */}
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-2">
               
              </div>
              {/* === BOTTOM RIGHT === */}
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 bottom-3">
                <Link to="/Terms">TERM OF USE</Link> | 
                <Link to="/Privacy">PRIVACY POLICY</Link> 
              </div>
            </div>
          </div>
        </div>
        {/* ===================================
		END BOTTOM SECTION
	======================================== */}
        {/* =========================
	   SLIDE MENU
	============================== */}
        <aside id="slide-menu" className="slide-menu">
          {/* === CLOSE MENU BUTON === */}	
          <div className="close-menu" id="close-menu">
            <i className="fa fa-close" />
          </div>
          {/* === SLIDE MENU === */}	
          <ul id="left-menu" className="left-menu">
            {/* === SLIDE MENU ITEM === */}	
            <li> 
              <Link to="#">Home <i className="fa fa-plus arrow" /></Link>
              {/* === slide menu child === */}	
              <ul className="slide-menu-child">
                <li><Link to="01_home.html">Home 1</Link></li>
                <li><Link to="/02_home.html">Home 2</Link></li>
              </ul>
            </li>
            {/* === SLIDE MENU ITEM === */}	
            <li> 
              <Link to="#">Service <i className="fa fa-plus arrow" /></Link>
              {/* === slide menu child === */}	
              <ul className="slide-menu-child">
                <li><Link to="/03_services.html">Service 1</Link></li>
                <li><Link to="/04_services.html">Service 2</Link></li>
                <li><Link to="/05_service_detail.html">Service Details</Link></li>
              </ul>
            </li>
            {/* === SLIDE MENU ITEM === */}	
            <li> 
              <Link to="/06_about.html">About us</Link>
            </li>
            {/* === SLIDE MENU ITEM === */}	
            <li> 
              <Link to="#">Elements <i className="fa fa-plus arrow" /></Link>
              {/* === slide menu child === */}	
              <ul className="slide-menu-child">
                <li><Link to="/07_typography.html">Typography</Link></li>
                <li><Link to="/08_buttons.html">Buttons</Link></li>
                <li><Link to="/09_progress.html">Progress Bars</Link></li>
                <li><Link to="/10_alerts.html">Alerts</Link></li>
              </ul>
            </li>
            {/* === SLIDE MENU ITEM === */}	
            <li>
              <Link to="#">Blog <i className="fa fa-plus arrow" /></Link>
              {/* === slide menu child === */}	
              <ul className="slide-menu-child">
                <li><Link to="/11_blog.html">Blog items</Link></li>
                <li><Link to="/12_blog_detail.html">Single Post</Link></li>
              </ul>
            </li>
            {/* === SLIDE MENU ITEM === */}	
            <li>
              <Link to="/13_contacts.html">Contacts</Link>
            </li>
          </ul>
        </aside>
        {/* =========================
	   END SLIDE MENU
	============================== */}
        {/* =========================
	   BLACK OVERLAY
	============================== */}
        <div className="black-overlay" id="black-overlay" />
        {/* =========================
	   END BLACK OVERLAY
	============================== */}
        <div className="colors">
          <div className="colors-item">
            <div className="colors-item-sq sq-blue" />
          </div>
          <div className="colors-item">
            <div className="colors-item-sq sq-red" />
          </div>
          <div className="colors-item">
            <div className="colors-item-sq sq-green" />
          </div>
          <div className="colors-item showcolor">
            <div className="colors-item-icon"><i className="fa fa-cog" /></div>
          </div>
        </div>

      <Outlet />
    </>
  );
};

export default Footer;
