import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>RAIL FREIGHT</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">RAIL FREIGHT</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li>
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li className="active">
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li>
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>RAIL FREIGHT</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/rail.webp" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    Rail freight and road freight are the two main contenders in
                    the overland transport arena, with inland shipping playing a
                    secondary role due to its limited route network. However,
                    viewing road and rail merely as competitors is
                    short-sighted. Instead, they can complement each other in
                    intermodal transport concepts. While road can be a valuable
                    complement to rail, it often becomes a necessity when the
                    destination lacks rail connectivity or when the rail system
                    is overwhelmed.
                    <br />
                    <br />
                    One key disadvantage of rail transport is its limited reach,
                    which can be mitigated by utilizing road transport where
                    necessary. Despite this limitation, rail boasts significant
                    advantages, particularly its positive ecological impact,
                    making it an environmentally-friendly choice. To make an
                    informed decision on the best mode of transport for specific
                    cases, it's crucial to weigh the pros and cons of rail and
                    road freight comprehensively.
                    <br />
                    <br />
                    By assessing the strengths and weaknesses of both modes,
                    businesses can make well-informed choices that optimize
                    their logistics operations. While rail shines in terms of
                    sustainability, road freight comes to the rescue in
                    scenarios where rail connectivity is lacking or capacity is
                    stretched thin. This balanced approach ensures efficient and
                    sustainable transportation solutions for various
                    destinations and cargo types. Embracing the synergy between
                    rail and road transport enables businesses to create
                    effective intermodal strategies, advancing both efficiency
                    and environmental responsibility in the logistics industry.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
