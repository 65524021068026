import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  

  return (
    <>
       <div className="main-navbar main-navbar-1" id="main-navbar">
          <div className="container">
            <div className="row">
              {/* === TOP LOGO === */}
              <div className="logo" id="main-logo">
              
                  <img src="img/logo.png" style={{height:"80px"}}></img>
              </div>
              {/* === TOP SEARCH === */}
             
              
              <div className="show-menu-control">
                {/* === top search button show === */}
                <Link id="show-menu" className="show-menu" to="#">
                  <div className="my-btn my-btn-primary">
                    <div className="my-btn-bg-top" />
                    <div className="my-btn-bg-bottom" />
                    <div className="my-btn-text">
                      <i className="fa fa-bars" />
                    </div>
                  </div>
                </Link>
              </div>
              {/* === TOP MENU === */}
              <div className="collapse navbar-collapse main-menu main-menu-1" id="main-menu">
                <ul className="nav navbar-nav">
                  {/* === top menu item === */}
                  <li>
                    <Link data-toggle="dropdown" to="/Home">Home</Link>
                    
                  </li>
                  <li className="main-menu-separator" />
                  {/* === top menu item === */}
                  
                  {/* === top menu item === */}
                  <li>
                    <Link to="/About">About us</Link>
                  </li> 
                  <li className="main-menu-separator" />

                  <li className="dropdown">
                    <Link data-toggle="dropdown" to="/Services">Freight Solutions</Link>
                    <ul className="dropdown-menu" role="menu">
                      <li>
                        <Link to="/Ocean">Ocean Freight</Link>
                      </li>
                      <li>
                        <Link to="/Air">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Road">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Ocean">OCEAN FREIGHT</Link>
                      </li>
                      <li>
                        <Link to="/Multimodal">Multimodal Transport</Link>
                      </li>
                      <li>
                        <Link to="/Warehousing">Warehousing</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="main-menu-separator" />
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li> 
                  <li className="main-menu-separator" />
                  <li>
                    <Link to="/Getquote">Request A Quote</Link>
                  </li> 
                 
                </ul>
              </div>
            </div>
          </div>
        </div>

      <Outlet />
    </>
  );
};

export default Header;
