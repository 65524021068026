import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>MULTIMODAL TRANSPORT</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">MULTIMODAL TRANSPORT</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li>
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li className="active">
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li>
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>MULTIMODAL TRANSPORT</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/multimodal.jpg" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    In specific markets, we excel in facilitating seamless cargo
                    movement across borders, harnessing our specialized
                    expertise and extensive presence. Our comprehensive
                    knowledge of over-border handling ensures the utmost safety
                    and smooth processing of your valuable cargo. With a vast
                    network of offices, we possess the necessary local insights
                    and connections to obtain the required permits and
                    clearances, streamlining the logistics process for you.
                    <br />
                    <br />
                    For inland transport solutions, we meticulously plan and
                    strategically select from a range of transportation options,
                    allowing us to devise the most efficient combination of
                    speed and cost-effectiveness. Our aim is to optimize your
                    supply chain, providing you with tailored solutions that
                    meet your specific requirements and enhance your overall
                    business performance.
                    <br />
                    <br />
                    Rest assured, our support services and escorts are readily
                    available wherever necessary, ensuring the secure and timely
                    movement of your cargo throughout its journey. Our
                    commitment to compliance is unwavering; we adhere to all
                    regulatory codes and industry standards, ensuring that your
                    cargo is in full compliance with all applicable laws and
                    regulations.
                    <br />
                    <br />
                    By choosing our freight forwarding services, you gain access
                    to our unparalleled expertise in cross-border operations and
                    inland transport solutions. Experience the convenience of a
                    reliable partner who caters to your unique needs, offering
                    you a comprehensive suite of services to enhance your
                    logistics experience and drive success in your global
                    operations.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
