import Header from "../components/Header";
import Footer from "../components/Footer";
import { useState } from "react";
import $ from "jquery";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };

  return (
    <>
      <Header />
      <div
        id="main-slider-1"
        className="main-slider main-slider-1 w3-banner jarallax"
      >
        <video className="img-fluid" autoPlay muted loop style={{}}>
          <source src="img/video.mp4" type="video/mp4" />
        </video>
        <div className="sp-slides">
          {/* === SLIDER ITEM === */}

          {/* === SLIDER ITEM === */}

          {/* === SLIDER ITEM === */}
          <div className="sp-slide">
            {/* === slide overlay === */}
            <div className="main-slider-overlay" />
            {/* === slide image === */}
            <img
              className="sp-image"
              src="/assets/slider-pro/css/images/blank.gif"
              data-src="/media/main-slider/3.jpg"
              data-retina="media/main-slider/3.jpg"
              alt=""
            />
            {/* === slide container === */}
            <div className="container">
              <div className="main-slider-content">
                <h2
                  className="sp-layer"
                  data-position="rightCenter"
                  data-horizontal={15}
                  data-vertical={-92}
                  data-show-transition="up"
                  data-hide-transition="up"
                  data-show-delay={200}
                  data-hide-delay={200}
                >
                  Navigating the World of Shipping,
                  <br /> with Confidence.
                </h2>
                <h2
                  className="sp-layer color-primary"
                  data-position="rightCenter"
                  data-horizontal={15}
                  data-vertical={-40}
                  data-show-transition="up"
                  data-hide-transition="up"
                  data-show-delay={400}
                  data-hide-delay={400}
                >
                  Qualitied Services of Freight Forwarding
                </h2>
                <Link
                  className="sp-layer main-button"
                  to="#"
                  data-position="rightCenter"
                  data-horizontal={15}
                  data-vertical={55}
                  data-show-transition="up"
                  data-hide-transition="up"
                  data-show-delay={600}
                  data-hide-delay={600}
                >
                  <div className="my-btn my-btn-primary">
                    <div className="my-btn-bg-top" />
                    <div className="my-btn-bg-bottom" />
                    <div className="my-btn-text">GET A FREE QUOTE</div>
                  </div>
                </Link>
                {/* === slide buttons === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* =========================
		END MAIN SLIDER
	============================== */}
      {/* =========================
		SERVICES
	============================== */}
      <section className="def-section home-blog">
        <div className="container">
          <div className="row">
            {/* === TITLE GROUP === */}
            <div className="title-group">
              <h2>Freight Solutions We Provide..</h2>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/ocean.jpg"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Ocean">Ocean Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> At {companyname}, we are dedicated to ensuring that your
                    global ocean freight moves smoothly and efficiently around
                    the world. With our extensive network and major ocean
                    shipping alliances, you can access more capacity and
                    competitive rates, optimizing your ocean shipping
                    operations.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img src="/img/air.jpg" alt="" style={{ height: "200px" }} />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Air">Air Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p>  At {companyname}, we take pride in offering specialized
                    expertise and extensive experience in international
                    airfreight cargo services. When you choose us to move your
                    cargo, you gain access to a wealth of benefits that ensure a
                    seamless and efficient transportation process.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/road.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Road">Road Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p>  If you're in search of a reliable and trusted logistics
                    partner who prioritizes your goals, look no further. At our
                    company, we understand the importance of matching your
                    ambitions and putting your needs first.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/rail.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Rail">Rail Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> Rail freight and road freight are the two main contenders in
                    the overland transport arena, with inland shipping playing a
                    secondary role due to its limited route network. However,
                    viewing road and rail merely as competitors is
                    short-sighted.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/multimodal.jpg"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Multimodal">Multimodal Transport</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> In specific markets, we excel in facilitating seamless cargo
                    movement across borders, harnessing our specialized
                    expertise and extensive presence. Our comprehensive
                    knowledge of over-border handling ensures the utmost safety
                    and smooth processing of your valuable cargo.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/warehousing.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Warehsusing">Warehsusing</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> Leverage the exceptional capabilities of our
                    state-of-the-art warehouses, meticulously designed to meet
                    the demands of modern logistics. Equipped with the latest
                    technology and operated by a team of experienced
                    professionals, our facilities stand ready to cater to your
                    cargo needs across various regions.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="def-section about-quote">
        <div className="section-bg-left" />
        <div className="section-bg-right" />
        <div className="container">
          <div className="row">
            {/* === ABOUT US === */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 home-about" style={{padding:"100px 25px"}}>
              {/* === TITLE GROUP === */}
              <div className="title-group">
                <h2>ABOUT US</h2>
              </div>
              {/* === ABOUT US TEXT === */}
              <p>
                At our freight company, we take pride in our rich heritage,
                which has been built on years of experience and a deep
                understanding of the freight forwarding industry. Our local and
                global expertise allows us to navigate the complexities of
                shipping and logistics, ensuring that your cargo reaches its
                destination safely and on time. With a vast network of partners
                and resources, we offer you unique comfort and peace of mind,
                knowing that your freight is in capable hands. <br />
                When you choose us, you benefit from our ability to leverage
                global synergies and tap into our in-depth knowledge of local
                markets. This combination allows us to tailor our freight
                forwarding solutions to meet your specific needs and
                requirements. Whether you are shipping internationally or
                domestically, our team of experts will work closely with you to
                ensure a seamless and efficient shipping process.
                <br />
              </p>
              <div className="home-about-video">
                <img
                  className=""
                  style={{ width: "100%" }}
                  src="img/about.jpg"
                ></img>

                {/* === READ MORE BUTTON === */}
                <Link to="/About">
                  <div className="home-about-button">
                    <div className="my-btn my-btn-primary">
                      <div className="my-btn-bg-top" />
                      <div className="my-btn-bg-bottom" />
                      <div className="my-btn-text">Learn More</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* === GET QUOTE === */}
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 get-quote" >
              {/* === TITLE GROUP === */}
              <div className="title-group">
                <h2>CONTACT US</h2>
                
              </div>
              {/* === GET QUOTE TEXT === */}
              <h3>
                    Don't hesitate to contact us – our team is ready to provide
                    tailored solutions for your freight forwarding requirements.
                  </h3>
              {/* === GET QUOTE FORM=== */}
              <div className="get-quote-form row">
                <div className="send-result" />
                <form
                  action="/php/thankyou-contact.php "
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="">
                  <div className="col-12">

                        <input
                          onChange={(event) => handleFnameChange(event)}
                          type="text"
                          className
                          id="fname"
                          name="fname"
                          value={fname}
                          placeholder="Enter your first name"
                          style={{
                            width: "100%",
                           
                            margin: "6px 0",
                          }}
                        />
                        <span />
                    </div>
                    <div className="col-12">
                        <input
                          onChange={(event) => handlelnameChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                           
                            margin: "6px 0",
                          }}
                          id="lname"
                          name="lname"
                          value={lname}
                          placeholder="Enter your last name"
                        />
                    </div>
                    <div className="col-12">
                      <div className="">
                        <input
                          onChange={(event) => handleemailChange(event)}
                          type="email"
                          style={{
                            width: "100%",
                           
                            margin: "6px 0",
                          }}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="Enter your Email"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <input
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                           
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          value={subject}
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="">
                        <textarea
                          style={{
                            width: "100%",
                           
                            margin: "6px 0",
                          }}
                          id="message"
                          name="message"
                          rows={1}
                          placeholder="Enter Message"
                          defaultValue={""}
                          value={message}
                          onChange={(event) => handlemessageChange(event)}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center"style={{width:"100%"}}>
                      <button type="submit">
                        <span className="my-btn my-btn-grey">
                          <span className="my-btn-bg-top" />
                          <span className="my-btn-bg-bottom" />
                          <span className="my-btn-text">Send Message</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
                <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ===================================
		END SECTION ABOUT US AND GET QUOTE
	======================================== */}
      {/* ===================================
	    SECTION REVIEWS AND FAQ
	======================================== */}
     
      <div
        className="def-section stats"
        id="home-stats"
        data-stellar-background-ratio="0.4"
      >
        <div className="stats-overlay" />
        <div className="container">
          <div className="row">
            {/* === STATS ITEM === */}
            <div className="col-lg-6 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item">
                <div className="stat-item-icon">
                  <i className="fa fa-bullseye" />
                </div>
                <div className="stat-item-number" id="num1"style={{float:"none"}}>
                  Our Mission
                </div>
                <div className="stat-item-text">
                Deliver customer-centric logistics magic worldwide through proactive digital solutions and effective governance.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-3 col-sm-6 col-xs-12">
              <div className="stat-item">
                <div className="stat-item-icon">
                  <i className="fa fa-eye" />
                </div>
                <div className="stat-item-number" id="num1"style={{float:"none"}}>
                  Our Vision
                </div>
                <div className="stat-item-text">
                Providing comprehensive and reliable freight forwarding services to ensure your goods are delivered safely and efficiently, anywhere in the world.
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>

     

      <Footer />
    </>
  );
}
