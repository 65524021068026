import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>ABOUT US</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">ABOUT US</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section about-text">
        <div className="container">
          <div className="about-text-image">
            <img src="img/about.jpg" alt="" />
          </div>
          <h2>Efficient Logistics, Exponential Growth.</h2>
          <p>
            At our freight company, we take pride in our rich heritage, which
            has been built on years of experience and a deep understanding of
            the freight forwarding industry. Our local and global expertise
            allows us to navigate the complexities of shipping and logistics,
            ensuring that your cargo reaches its destination safely and on time.
            With a vast network of partners and resources, we offer you unique
            comfort and peace of mind, knowing that your freight is in capable
            hands.
            <br />
            <br />
            When you choose us, you benefit from our ability to leverage global
            synergies and tap into our in-depth knowledge of local markets. This
            combination allows us to tailor our freight forwarding solutions to
            meet your specific needs and requirements. Whether you are shipping
            internationally or domestically, our team of experts will work
            closely with you to ensure a seamless and efficient shipping
            process.
            <br />
            <br />
            One of the key features that sets us apart is our advanced technical
            tools, which provide you with real-time tracking and visibility of
            your cargo. With just a few clicks, you can book your shipment,
            trace its journey, and access a wealth of information and features.
            We understand that customer convenience is of utmost importance, and
            we go the extra mile to make information exchange easy and
            hassle-free, ensuring that you are always informed about the status
            of your freight.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
