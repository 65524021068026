import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>Services</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">Services</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <section className="def-section home-blog">
        <div className="container">
          <div className="row">
            {/* === TITLE GROUP === */}
            <div className="title-group">
              <h2>Freight Solutions We Provide..</h2>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/ocean.jpg"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Ocean">Ocean Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> At {companyname}, we are dedicated to ensuring that your
                    global ocean freight moves smoothly and efficiently around
                    the world. With our extensive network and major ocean
                    shipping alliances, you can access more capacity and
                    competitive rates, optimizing your ocean shipping
                    operations.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img src="/img/air.jpg" alt="" style={{ height: "200px" }} />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Air">Air Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p>  At {companyname}, we take pride in offering specialized
                    expertise and extensive experience in international
                    airfreight cargo services. When you choose us to move your
                    cargo, you gain access to a wealth of benefits that ensure a
                    seamless and efficient transportation process.</p>
                  </div>
                </div>
              </div>
            </div>
            {/* === BLOG ITEM === */}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/road.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Road">Road Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p>  If you're in search of a reliable and trusted logistics
                    partner who prioritizes your goals, look no further. At our
                    company, we understand the importance of matching your
                    ambitions and putting your needs first.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/rail.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Rail">Rail Freight</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> Rail freight and road freight are the two main contenders in
                    the overland transport arena, with inland shipping playing a
                    secondary role due to its limited route network. However,
                    viewing road and rail merely as competitors is
                    short-sighted.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/multimodal.jpg"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Multimodal">Multimodal Transport</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> In specific markets, we excel in facilitating seamless cargo
                    movement across borders, harnessing our specialized
                    expertise and extensive presence. Our comprehensive
                    knowledge of over-border handling ensures the utmost safety
                    and smooth processing of your valuable cargo.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="home-blog-item">
                <div className="home-blog-item-image">
                  <img
                    src="/img/warehousing.webp"
                    alt=""
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="home-blog-item-desc">
                  <div className="home-blog-item-desc-title">
                    <Link to="/Warehsusing">Warehsusing</Link>
                  </div>

                  <div className="home-blog-item-desc-text">
                    <p> Leverage the exceptional capabilities of our
                    state-of-the-art warehouses, meticulously designed to meet
                    the demands of modern logistics. Equipped with the latest
                    technology and operated by a team of experienced
                    professionals, our facilities stand ready to cater to your
                    cargo needs across various regions.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
