import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>ROAD FREIGHT</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">ROAD FREIGHT</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li>
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li className="active">
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li>
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>ROAD FREIGHT</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/road.webp" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    If you're in search of a reliable and trusted logistics
                    partner who prioritizes your goals, look no further. At our
                    company, we understand the importance of matching your
                    ambitions and putting your needs first.
                    <br />
                    <br />
                    Our teams of experts combine their global knowledge with
                    local expertise, ensuring that no matter what you're
                    shipping – from engines to eggs – your cargo will reach its
                    destination safely and on time, providing you with peace of
                    mind and the freedom to concentrate on your core business.
                    <br />
                    <br />
                    In a rapidly changing world, yesterday's solutions may not
                    be suitable for tomorrow. Thanks to our expansive network
                    and experienced teams, we can adapt swiftly and customize
                    our services to meet your specific requirements, giving you
                    a competitive edge.
                    <br />
                    <br />
                    We speak your language when it comes to understanding your
                    business. Our digital and innovative solutions provide you
                    with full visibility of your products, allowing you to track
                    and manage your assets with greater efficiency. With the
                    ability to monitor cargo and information flows, you can take
                    proactive measures and transform data into valuable
                    information for effective decision-making.
                    <br />
                    <br />
                    Choose us as your logistics partner, and together, we'll
                    achieve your objectives and move your business forward in
                    this dynamic and ever-evolving marketplace.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
