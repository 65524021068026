import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="page-header" data-stellar-background-ratio="0.4">
        <div className="page-header-overlay" />
        <div className="container">
          <div className="row">
            {/* === PAGE HEADER TITLE === */}
            <div className="page-header-title">
              <h2>AIR FREIGHT</h2>
            </div>
            {/* === PAGE HEADER BREADCRUMB === */}
            <div className="page-header-breadcrumb">
              <ol className="breadcrumb">
                <li>
                  <Link to="/Home">Home</Link>
                </li>
                <li className="active">AIR FREIGHT</li>
              </ol>
            </div>
            {/* === PAGE HEADER BUTTON === */}
            <div className="page-header-button">
              <Link to="/Getquote">
                <div className="my-btn my-btn-primary">
                  <div className="my-btn-bg-top" />
                  <div className="my-btn-bg-bottom" />
                  <div className="my-btn-text">GET A FREE QUOTE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="def-section services-detail">
        <div className="container">
          <div className="row">
            {/* === SERVICES MENU === */}
            <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <ul className="service-menu">
                <li>
                  <Link to="/Services">ALL SERVICES</Link>
                </li>
                <li>
                  <Link to="/Ocean">OCEAN FREIGHT</Link>
                </li>
                <li className="active">
                  <Link to="/Air">AIR FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Road">ROAD FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Rail">RAIL FREIGHT</Link>
                </li>
                <li>
                  <Link to="/Multimodal">MULTIMODAL TRANSPORT</Link>
                </li>
                <li>
                  <Link to="/Warehousing">WAREHOUSING</Link>
                </li>
              </ul>
            </div>
            {/* === SERVICE ITEM DESCRIPTION === */}
            <div className="col-lg-9 col-md-9 col-sm-9 col-xs-12">
              <div className="service-detail">
                {/* === SERVICE ITEM TITLE === */}
                <h2>Air Freight</h2>
                {/* === SERVICE ITEM IMAGE === */}
                <div className="service-detail-bigimage">
                  <img src="img/air.jpg" alt="" />
                </div>
                {/* === SERVICE ITEM TEXT === */}
                <div className="row">
                  <p>
                    At {companyname}, we take pride in offering specialized
                    expertise and extensive experience in international
                    airfreight cargo services. When you choose us to move your
                    cargo, you gain access to a wealth of benefits that ensure a
                    seamless and efficient transportation process.
                    <br />
                    <br />
                    Our team possesses detailed knowledge of various aircraft
                    types and their capacities, enabling us to optimize the
                    selection of aircraft for your specific cargo requirements.
                    We understand the intricacies of local regulations across
                    different continents, ensuring compliance and smooth
                    operations throughout your cargo's journey. With our
                    time-bound shipments, you can trust that your cargo will
                    reach its destination on schedule, every time. Additionally,
                    we provide competitive air cargo rates, helping you optimize
                    your transportation costs without compromising on quality.
                    <br />
                    <br />
                    Our vast and well-connected network spans across the globe,
                    providing accessibility to even the most remote locations.
                    No matter where your cargo needs to go, we have the
                    capabilities to deliver.
                    <br />
                    <br />
                    At {companyname}, we also offer value-added services such as
                    inland trucking service and warehouse capabilities, ensuring
                    end-to-end transit with utmost safety and security. Our
                    local expertise in handling customs and compliance ensures a
                    hassle-free and efficient clearance process for your cargo.
                    <br />
                    <br />
                    When you partner with {companyname} for your airfreight
                    cargo needs, you can rest assured that you are in capable
                    hands. We are committed to delivering excellence in every
                    aspect of our services, making your cargo transportation
                    experience a smooth and reliable one.
                  </p>
                </div>
                {/* === SERVICE RELATED ITEMS === */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
